import React from 'react';
import PropTypes from 'prop-types';
import ReactTransitionGroup from 'react-addons-transition-group';
import classnames from 'classnames';
import styled from 'styled-components';
import Color from 'color';
import { defaultTheme } from '../../constants/appConfig';
import FirstChild from '../common/FirstChild';
import AnimatedErrorMessage from './AnimatedErrorMessage';
import newid from '../../utils/newid';
import { FormContext } from './Form';
import Svg from './Svg';

function getRandomInt() {
  return newid() + Date.now() + (Math.random() * 1000).toFixed();
}

const StyledUiTextarea = styled.div`
  .input-wrap {
    &:hover {
      &:before {
        background-color: ${Color('#d6d5d6').darken(0.2).hex()};
      }
      .stroke-colored-el {
        stroke: ${Color('#C8C8C8').darken(0.2).hex()};
      }
      .fill-colored-el {
        fill: ${Color('#C8C8C8').darken(0.2).hex()};
      }
    }
  }
  &.focused {
    .input-wrap {
      &:before {
        background-color: ${props => (props.theme ? props.theme.primaryBgColor : defaultTheme.primaryBgColor)};
      }
    }
  }
  &.focused,
  &.filled {
    .input-wrap {
      .stroke-colored-el {
        stroke: ${props => (props.theme ? props.theme.primaryBgColor : defaultTheme.primaryBgColor)};
      }
      .fill-colored-el {
        fill: ${props => (props.theme ? props.theme.primaryBgColor : defaultTheme.primaryBgColor)};
      }
    }
  }

  &.invalid {
    .input-wrap {
      &:before {
         background-color: #ff5252;
      }
    }
  }
`;


class Textarea extends React.PureComponent {
  static propTypes = {
    value: PropTypes.string,
    tabIndex: PropTypes.string,
    label: PropTypes.string.isRequired,
    name: PropTypes.string,
    className: PropTypes.string,
    valid: PropTypes.bool,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    message: PropTypes.string,
    iconPath: PropTypes.string,
    customWidth: PropTypes.number,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
  };

  static defaultProps = {
    onChange: () => {},
    onFocus: () => {},
    onBlur: () => {},
    value: '',
    tabIndex: '0',
    name: '',
    className: undefined,
    valid: undefined,
    disabled: false,
    readonly: false,
    message: '',
    iconPath: '',
    customWidth: 100,
  };

  static contextType = FormContext;

  constructor(props) {
    super(props);

    this.name = getRandomInt();
    this.input = null;

    this.state = {
      onceTouched: false,
      focused: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
  }

  componentDidMount() {
    if (this.context) {
      this.context.onInputAddedToForm(this.input);
    }
    this.setInputElementHeight();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setInputElementHeight();
    }
  }

  /**
   * @param {Event} e
   */
  onChange(e) {
    const { target } = e;
    this.props.onChange(target.value);

    // target.style.height = 'auto';
    // target.style.height = `${target.scrollHeight + 1}px`; // https://stackoverflow.com/a/25621277/7140603 (+1 it seems fix problem of shaking after first letter entering)
  }

  onFocus() {
    this.props.onFocus();
    this.setState({ focused: true, onceTouched: true });
  }

  onBlur() {
    this.props.onBlur();
    this.setState({ focused: false });
  }

  setInputElementHeight() {
    if (this.input) {
      this.input.style.height = 'auto';
      this.input.style.height = `${this.input.scrollHeight + 1}px`; // https://stackoverflow.com/a/25621277/7140603 (+1 it seems fix problem of shaking after first letter entering)
    }
  }

  render() {
    const {
      value, tabIndex, label, name, className, valid, disabled, readonly, message, iconPath, customWidth,
    } = this.props;
    const { focused, onceTouched } = this.state;

    const classname = classnames('ui-textarea', className, {
      disabled,
      readonly,
      focused,
      onceTouched,
      filled: value && value.length,
      valid: valid === true,
      invalid: valid === false,
    });

    return (
      <StyledUiTextarea className={classname} style={{ width: `${customWidth}%` }}>
        <label htmlFor={this.name}>{label}</label>
        <div className="input-wrap">
          <textarea
            ref={ref => this.input = ref}
            rows="1"
            name={name}
            value={value}
            disabled={disabled}
            readOnly={readonly}
            onChange={this.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            tabIndex={tabIndex}
          />
          {iconPath && (
            <div className="input-icon">
              <Svg path={iconPath} className="input-icon-svg" />
            </div>
          )}
        </div>
        <ReactTransitionGroup component={FirstChild}>
          {valid === false ? <AnimatedErrorMessage><span dangerouslySetInnerHTML={{ __html: message }} /></AnimatedErrorMessage> : null}
        </ReactTransitionGroup>
      </StyledUiTextarea>
    );
  }
}

export default Textarea;
