import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import CloseIcon from '../assets/svg/close.svg';
import SuccessIcon from '../assets/svg/finish-success.svg';
import ErrorIcon from '../assets/svg/finish-error.svg';

import '../styles/pageFinish.scss';
import { closeEvent, errorEvent, successEvent } from '../utils/api-widget';
import { parseQuery } from '../utils/parseQueryString';

const eventCallbackByStatus = {
  success: successEvent,
  error: errorEvent,
};

const iconByStatus = {
  success: SuccessIcon,
  error: ErrorIcon,
};

class PageFinish extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { match: { params: { status } }, location } = this.props;
    const queryParams = parseQuery(location.search);

    eventCallbackByStatus[status]({ ...queryParams });
  }

  onCloseClick = () => {
    closeEvent();
  };

  render() {
    const { match: { params: { status } }, location, t, i18n } = this.props;

    const queryParams = parseQuery(location.search);
    const cardBrand = `${t(`card_brands.${queryParams.brand}`)} `.toUpperCase();
    const cardPan = queryParams.maskedPan ? ` ••••&nbsp;${queryParams.maskedPan}` : '';
    const Icon = iconByStatus[status];
    const isConfirmationIframe = window.name === 'teko-confirmation-iframe' || (window.frameElement && window.frameElement.id === 'teko-confirmation-iframe');

    return (
      <div className="page-finish">
        <div className="page-finish-container">
          {!isConfirmationIframe && (
            <button className="close-btn" type="button" onClick={this.onCloseClick}>
              <CloseIcon />
            </button>
          )}
          <p className="finish-text" dangerouslySetInnerHTML={{ __html: i18n.exists(`card_brands.${queryParams.brand}`) && cardPan ? t(`finish.${status}`, { cardBrand, cardPan }) : t(`finish.unknown-brand-card-${status}`, { cardPan }) }} />
          <Icon className="finish-icon" />
        </div>
      </div>
    );
  }
}

export default withTranslation()(PageFinish);
