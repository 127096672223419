import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

export default class Alert extends React.PureComponent {
  static propTypes = {
    color: PropTypes.string,
    closeTimeoutMS: PropTypes.number,
    isOpen: PropTypes.bool,
    closeOnClick: PropTypes.bool,
    onClose: PropTypes.func,
    children: PropTypes.node,
  };

  static defaultProps = {
    color: 'white',
    closeTimeoutMS: 3500,
    isOpen: false,
    children: null,
    closeOnClick: false,
    onClose: () => {},
  };

  static Color = {
    blue: 'blue',
    green: 'green',
    yellow: 'yellow',
    red: 'red',
    white: 'white',
  };

  constructor(props) {
    super(props);

    this.timeoutId = 0;
  }

  componentDidUpdate() {
    if (this.props.isOpen) {
      if (this.props.closeTimeoutMS) {
        this.timeoutId = setTimeout(this.props.onClose, this.props.closeTimeoutMS);
      }
    }
  }

  componentWillUnmount() {
    if (this.props.isOpen) {
      this.props.onClose();
    }
    clearTimeout(this.timeoutId);
  }

  onClick = () => {
    if (this.props.closeOnClick) {
      this.props.onClose();
    }
  };

  render() {
    const className = classnames(`ui-alert ui-alert-${this.props.color}`, { 'ui-alert-closable-on-click': this.props.closeOnClick });

    return (
      <ReactCSSTransitionGroup
        transitionName="ui-alert"
        transitionEnterTimeout={300}
        transitionLeaveTimeout={300}
      >
        {!this.props.isOpen ? null : (
          <div className={className} onClick={this.onClick}>
            {this.props.children}
          </div>
        )}
      </ReactCSSTransitionGroup>
    );
  }
}
