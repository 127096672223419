import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import '../styles/pageError.scss';

class PageClose extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  render() {
    return (
      <div className="page-error">
        <div className="page-error-container">
          <h1>Processing...</h1>
        </div>
      </div>
    );
  }
}

export default withTranslation()(PageClose);
