const opener = global.opener || global.top;

export function closeEvent() {
  if (opener) {
    opener.postMessage({ type: 'teko-bind-card-widget-CLOSE-BUTTON' }, '*');
  }
}

export function pendingEvent() {
  if (opener) {
    opener.postMessage({ type: 'teko-bind-card-widget-PENDING' }, '*');
  }
}

export function successEvent(data) {
  if (opener) {
    opener.postMessage({ type: 'teko-bind-card-widget-SUCCESS', data }, '*');
  }
}

export function errorEvent(data) {
  if (opener) {
    opener.postMessage({ type: 'teko-bind-card-widget-ERROR', data }, '*');
  }
}

export function redirectEvent(data) {
  if (opener) {
    opener.postMessage({ type: 'teko-bind-card-widget-REDIRECT', data }, '*');
  }
}
