import { connect } from 'react-redux';
import { updateStoreDataField } from '../actions/data';
import App from './App';

const mapStateToProps = state => ({
  data: state.data,
});

const mapDispatchToProps = {
  updateStoreDataField,
};

const AppContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);

export default AppContainer;
