import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import CustomScroll from 'react-custom-scroll';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import withViewport from '../viewport/withViewport';
import { ViewportSize } from '../viewport/Viewport';


class CustomScrollWrap extends PureComponent {
  static propTypes = {
    innerRef: PropTypes.any,
    children: PropTypes.node.isRequired,
    viewport: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    innerRef: null,
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { innerRef, children, viewport } = this.props;

    if (viewport.size === ViewportSize.DESKTOP) {
      return (
        <CustomScroll ref={innerRef} keepAtBottom>
          {children}
        </CustomScroll>
      );
    }

    return children;
  }
}

const enhance = compose(
  withTranslation(),
  withViewport(),
);

const EnhancedScrollWrap = enhance(CustomScrollWrap);

const CustomScrollWrapWithRef = React.forwardRef((props, ref) => (
  <EnhancedScrollWrap innerRef={ref} {...props}>{props.children}</EnhancedScrollWrap>
));

export default CustomScrollWrapWithRef;
