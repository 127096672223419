import i18next from 'i18next';
import Cache from 'i18next-localstorage-cache';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

i18next
  .use(HttpApi)
  .use(Cache)
  .use(initReactI18next)
  // .use(LanguageDetector)
  .init({
    lng: 'ru', // TODO: lang detection
    fallbackLng: 'ru',

    // have a common namespace used around the full app
    ns: ['translation'],
    defaultNS: 'translation',

    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      addPath: '/locales/{{lng}}/{{ns}}.json',
    },

    debug: process.env.NODE_ENV === 'development',

    cache: {
      enabled: true, // process.env.NODE_ENV === 'production',
      expirationTime: 24 * 60 * 60 * 1000, // 1 day,
      versions: {
        en: '2017.04.19',
      },
    },

    interpolation: {
      escapeValue: false, // not needed for react!!
    },
  });

if (process.env.NODE_ENV !== 'production') {
  const { applyClientHMR } = require('i18next-hmr/client');
  applyClientHMR(i18next);
}

export default i18next;
