import React from 'react';
import PropTypes from 'prop-types';
import ReactTransitionGroup from 'react-addons-transition-group';
import classnames from 'classnames';
import InputMask from 'react-input-mask';
import styled from 'styled-components';
import Color from 'color';
import { defaultTheme } from '../../constants/appConfig';
import newid from '../../utils/newid';
import FirstChild from '../common/FirstChild';
import AnimatedErrorMessage from './AnimatedErrorMessage';
import { FormContext } from './Form';
import Svg from './Svg';

const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
const isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
const isWebkitBrowser = isChrome || isSafari;

const autoCompleteExpireDateRegexp = /^(0[1-9]|1[0-2])\/([0-9]{4})$/;
const fullFilledExpireDateRegexp = /^(0[1-9]|1[0-2]) \/ ([0-9]{2})$/;

function getRandomInt() {
  return newid() + Date.now() + (Math.random() * 1000).toFixed();
}

const StyledUiInput = styled.div`
  .input-wrap {
    &:hover {
      &:before {
        background-color: ${Color('#d6d5d6').darken(0.2).hex()};
      }
      .stroke-colored-el {
        stroke: ${Color('#C8C8C8').darken(0.2).hex()};
      }
      .fill-colored-el {
        fill: ${Color('#C8C8C8').darken(0.2).hex()};
      }
    }
  }
  &.focused {
    .input-wrap {
      &:before {
        background-color: ${props => (props.theme ? props.theme.primaryBgColor : defaultTheme.primaryBgColor)};
      }
    }
  }
  &.focused,
  &.filled {
    .input-wrap {
      .stroke-colored-el {
        stroke: ${props => (props.theme ? props.theme.primaryBgColor : defaultTheme.primaryBgColor)};
      }
      .fill-colored-el {
        fill: ${props => (props.theme ? props.theme.primaryBgColor : defaultTheme.primaryBgColor)};
      }
    }
  }
  &.invalid {
    .input-wrap {
      &:before {
         background-color: #ff5252;
      }
    }
  }
`;

export default class MaskInput extends React.PureComponent {
  static propTypes = {
    type: PropTypes.string,
    pattern: PropTypes.string,
    inputMode: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    value: PropTypes.string,
    tabIndex: PropTypes.string,
    autoComplete: PropTypes.string,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    valid: PropTypes.bool,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    message: PropTypes.string,
    mask: PropTypes.string.isRequired,
    maskChar: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    maxLength: PropTypes.number,
    iconPath: PropTypes.string,
    customWidth: PropTypes.number,
    prefix: PropTypes.node,
    alwaysShowMask: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
  };

  static defaultProps = {
    type: 'text',
    pattern: '',
    inputMode: '',
    className: '',
    name: '',
    valid: undefined,
    tabIndex: '0',
    autoComplete: '',
    disabled: false,
    readonly: false,
    maskChar: null,
    message: 'Field is required',
    maxLength: undefined,
    placeholder: '',
    value: '',
    iconPath: '',
    customWidth: 100,
    prefix: undefined,
    alwaysShowMask: false,
    onBlur: () => {},
    onFocus: () => {},
    onChange: () => {},
  };

  static contextType = FormContext;

  constructor(props) {
    super(props);

    this.name = getRandomInt();
    this.id = newid();
    this.input = null;

    this.state = {
      onceTouched: false,
      focused: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
  }

  componentDidMount() {
    if (this.context) {
      this.context.onInputAddedToForm(this.input);
    }
  }

  componentDidUpdate(prevProps) {
    // dirty hack [2] TODO: check if all ok
    if (prevProps.mask !== this.props.mask) {
      this.props.onChange(this.props.value);
    }
  }

  onChange(e) {
    this.props.onChange(e.target.value);
  }

  onBlur(e) {
    this.props.onBlur(e);
    this.setState({ focused: false });
  }

  onFocus(e) {
    this.props.onFocus(e);
    this.setState({ focused: true, onceTouched: true });
  }

  beforeMaskExpireDate = (newState, oldState, userInput) => {
    let value = newState.value;
    let selection = newState.selection;

    if (autoCompleteExpireDateRegexp.test(userInput)) {
      value = `${userInput.slice(0, 2)} / ${userInput.slice(-2)}`;
      selection = { start: newState.value.length, end: newState.value.length };
    }

    const newSelectionEnd = newState.selection && newState.selection.end;
    const oldSelectionEnd = oldState.selection && oldState.selection.end;

    // ¯\_(ツ)_/¯
    if (newSelectionEnd === 0 && oldSelectionEnd === 7) {
      selection = { start: 0, end: 7 };
    }

    // ¯\_(ツ)_/¯
    if (newState.value === '   /   ') {
      selection = { start: 0, end: 0 };
    }

    return { value, selection };
  };

  render() {
    const {
      className, disabled, readonly, value, valid, label, type, tabIndex, placeholder,
      message, mask, maskChar, maxLength, iconPath, customWidth, name,
      prefix, alwaysShowMask, pattern, inputMode, autoComplete,
    } = this.props;
    const { focused, onceTouched } = this.state;

    const finalType = type === 'password' && isWebkitBrowser ? 'text' : type;

    const classname = classnames('ui-input', className, {
      disabled,
      focused,
      onceTouched,
      prefix,
      secureMask: type === 'password' && isWebkitBrowser,
      filled: value && value.length,
      valid: valid === true,
      invalid: valid === false,
    });

    return (
      <StyledUiInput className={classname} style={{ width: `${customWidth}%` }}>
        <label htmlFor={this.name}>{label}</label>
        <div className="input-wrap">
          {prefix}
          <InputMask
            id={this.name}
            inputRef={ref => this.input = ref}
            type={finalType}
            pattern={pattern}
            inputMode={inputMode}
            name={autoComplete ? name : this.name}
            value={value}
            tabIndex={tabIndex}
            placeholder={placeholder}
            disabled={disabled}
            readOnly={readonly}
            onChange={this.onChange}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            mask={mask}
            maskChar={maskChar}
            maxLength={maxLength}
            autoComplete={autoComplete || this.name}
            alwaysShowMask={alwaysShowMask}
            beforeMaskedValueChange={name === 'cc-exp' ? this.beforeMaskExpireDate : undefined}
          />
          {iconPath && (
            <div className="input-icon">
              <Svg path={iconPath} className="input-icon-svg" />
            </div>
          )}
        </div>
        <ReactTransitionGroup component={FirstChild}>
          {valid === false ? <AnimatedErrorMessage><span dangerouslySetInnerHTML={{ __html: message }} /></AnimatedErrorMessage> : null}
        </ReactTransitionGroup>
      </StyledUiInput>
    );
  }
}
