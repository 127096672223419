const clearMask = (input, mask) => {
  let raw = '';
  for (const i in mask) {
    const char = mask[i];
    if (['1', '9', 'a', '*'].indexOf(char) !== -1) if (input[i]) raw += input[i];
  }
  return raw;
};

const fieldTypes = {
  amount: (value = '') => value * 100,
  phone_number: (value = '') => value.replace(/[^\d]/g, ''),
  card_number: (value = '') => value.replace(/[^\d]/g, ''),
  text: (value = '', mask) => (mask ? clearMask(value, mask) : value),
};

export const normalizeField = (field, value) => field && fieldTypes[field.type] && fieldTypes[field.type](value, field.mask) || value;

const normalizeCardData = ({ cvc, pan, expire, ...params }, hashFields) => {
  const newPan = pan && pan.replace(/\s/g, '').replace(/(.{6})(.{6})/, (_, b, c) => `${b}******`);

  const copyParams = { ...params };

  for (const name in copyParams) copyParams[name] = normalizeField(hashFields[name], copyParams[name]);

  return { ...copyParams, pan: newPan };
};

export default normalizeCardData;
