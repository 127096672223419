/* eslint-disable */
import 'whatwg-fetch';
import WebFont from 'webfontloader';
import 'moment/locale/ru'; // Here import all possible moment locales
import 'react-hot-loader'; // https://github.com/gaearon/react-hot-loader#getting-started
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';
import Root from './components/Root';
import './styles/index.scss';
import './i18n/i18n';

const render = (Component) => {
  ReactDOM.render(
      <Provider store={store}>
        <BrowserRouter >
          <Component />
        </BrowserRouter>
      </Provider>,
    window.document.getElementById('app'),
  );
};

WebFont.load({
  custom: {
    families: ['Lato'],
  },
  active: () => {
    render(Root);

   try {
     window.parent.postMessage({ type: 'teko-bind-card-active' }, '*');
   } catch (e) {
     console.error(e);
   }
  },
});
