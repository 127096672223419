import React from 'react';
import PropTypes from 'prop-types';

export default class AnimatedErrorMessage extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    enterTimeout: PropTypes.number,
    leaveTimeout: PropTypes.number,
  };

  static defaultProps = {
    enterTimeout: 300,
    leaveTimeout: 300,
  };

  constructor(props) {
    super(props);

    this.errorMsg = null;
    this.errorMsgContent = null;
    this.enterTimer = 0;
    this.leaveTimer = 0;
    this.animating = false;
  }

  componentWillEnter(callback) {
    clearTimeout(this.enterTimer);
    clearTimeout(this.leaveTimer);
    if (!this.errorMsg || !this.errorMsgContent) return;

    if (!this.animating) {
      this.errorMsg.style.opacity = '0';
      this.errorMsg.style.height = '0px';
    }

    this.errorMsg.getBoundingClientRect();

    this.animating = true;

    this.errorMsg.style.height = `${this.errorMsgContent.clientHeight}px`;
    this.errorMsg.style.opacity = '1';

    this.enterTimer = setTimeout(() => {
      callback();
    }, this.props.enterTimeout);
  }

  componentDidEnter() {
    if (!this.errorMsg) return;
    this.animating = false;
    this.errorMsg.style.height = '';
    this.errorMsg.style.opacity = '';
  }

  componentWillLeave(callback) {
    clearTimeout(this.enterTimer);
    clearTimeout(this.leaveTimer);
    if (!this.errorMsg || !this.errorMsgContent) return;

    if (!this.animating) {
      this.errorMsg.style.opacity = '1';
      this.errorMsg.style.height = `${this.errorMsgContent.clientHeight}px`;
    }

    this.errorMsg.getBoundingClientRect();

    this.animating = true;

    this.errorMsg.style.height = '0px';
    this.errorMsg.style.opacity = '0';

    this.leaveTimer = setTimeout(() => {
      callback();
    }, this.props.leaveTimeout);
  }

  componentDidLeave() {
    if (!this.errorMsg) return;
    this.animating = false;
    this.errorMsg.style.height = '';
    this.errorMsg.style.opacity = '';
  }

  render() {
    const { children } = this.props;

    return (
      <div className="ui-error-msg" ref={ref => this.errorMsg = ref}>
        <div className="ui-error-msg-content" ref={ref => this.errorMsgContent = ref}>
          {children}
        </div>
      </div>
    );
  }
}
