export function createAutoSubmitIframe(params) {
  const alreadyInsertedFirstOne = !!window.document['threeDs-2-hidden-iframe'];
  const iframe = window.document.createElement('iframe');
  if (params.html) {
    iframe.className = 'visible-3ds-iframe';
  }
  iframe.id = alreadyInsertedFirstOne ? 'threeDs-2-hidden-iframe-2' : 'threeDs-2-hidden-iframe';
  iframe.ariaHidden = 'true';
  iframe.tabIndex = -1;
  const html = params.html || `
            <body>
              <form name="form" action="${params.url}" method="post">
                  <input type="hidden" name="threeDSMethodData" value="${params.data}">
              </form>
            </body>
            <script>
                window.document.form.submit();
            </script>
          `;
  const container = params.html ? window.document.getElementById('main-content') : window.document.body;
  container.appendChild(iframe);
  iframe.contentWindow.document.open();
  iframe.contentWindow.document.write(html);
  iframe.contentWindow.document.close();
}
