import React from 'react';
import PropTypes from 'prop-types';
import { FormContext } from './Form';
import MaskInput from './MaskInput';
import Textarea from './Textarea';

// https://stackoverflow.com/questions/4817029/whats-the-best-way-to-detect-a-touch-screen-device-using-javascript
// like here: https://github.com/Modernizr/Modernizr/blob/master/feature-detects/touchevents.js
const isTouchDevice = (() => {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  const mq = function (query) {
    return window.matchMedia(query).matches;
  };

  if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
    return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return mq(query);
})();

export default class ValidateInput extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string,
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    pattern: PropTypes.string,
    inputMode: PropTypes.string,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    autoComplete: PropTypes.string,
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    tabIndex: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    validator: PropTypes.func,
    mask: PropTypes.string,
    maskChar: PropTypes.string,
    maxLength: PropTypes.number,
    customWidth: PropTypes.number,
    blockBlur: PropTypes.bool,
    iconPath: PropTypes.string,
    resetValidationOnFocus: PropTypes.bool,
    onlyLatinChars: PropTypes.bool,
    prefix: PropTypes.node,
    alwaysShowMask: PropTypes.bool,
  };

  static defaultProps = {
    name: '',
    className: '',
    disabled: false,
    readonly: false,
    type: 'text',
    pattern: '',
    inputMode: '',
    mask: '',
    maskChar: '',
    maxLength: undefined,
    value: '',
    defaultValue: '',
    tabIndex: '0',
    blockBlur: false,
    autoComplete: '',
    placeholder: '',
    iconPath: '',
    customWidth: 100,
    resetValidationOnFocus: true,
    onlyLatinChars: false,
    prefix: undefined,
    alwaysShowMask: false,
    validator: () => '',
    onBlur: () => {},
    onFocus: () => {},
    onChange: () => {},
  };

  static contextType = FormContext;

  constructor(props) {
    super(props);

    this.state = {
      value: '',
      message: '',
      valid: true,
      touched: false,
    };


    this.input = null;

    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
  }

  componentDidMount() {
    if (this.context) {
      this.context.onValidateInputAddedToForm(this);
    }

    const { defaultValue, value } = this.props;

    if (isTouchDevice && this.input.input) {
      this.input.input.disabled = true;
    }

    this.onChange(defaultValue || value || '', isTouchDevice && this.input.input);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { value } = nextProps;

    if (nextProps.value !== null && value !== this.state.value) {
      if (this.props.resetValidationOnFocus && this.context && this.context.formSubmitted) {
        this.setState({ value });
      } else {
        const message = this.props.validator(value) || '';
        this.setState({
          value,
          message,
          valid: !message,
        });
      }
    }
  }

  onChange(rawValue, withResetingDisabled) {
    // const isNum = /^\d+$/.test(value);
    const { type, name, mask, onlyLatinChars, validator, resetValidationOnFocus } = this.props;
    if (onlyLatinChars && /[^a-zA-Z ]/g.test(rawValue)) return;
    // if (this.props.name === 'cc-exp' && value.slice(0, 2).trim().length === 2 && !moment(value.slice(0, 2), 'MM').isValid()) return;

    let value = rawValue;

    if (type === 'number' || name === 'cvc') {
      value = value.replace(',', '.');
      if (value !== '' && isNaN(value) && !mask) return; // hack for type number because input with type number changing via scroll on it - it is bad feature
    }

    const message = validator(value) || '';

    if (resetValidationOnFocus && this.context && this.context.formSubmitted) {
      this.setState({ value }, () => {
        if (withResetingDisabled) {
          setTimeout(() => {
            if (this.input && this.input.input) {
              this.input.input.disabled = false; // ultra hack for disabling auto focus on iOS;
            }
          }, 10);
        }
      });
    } else {
      this.setState({
        value,
        message,
        valid: !message,
      }, () => {
        if (withResetingDisabled) {
          setTimeout(() => {
            if (this.input && this.input.input) {
              this.input.input.disabled = false; // ultra hack for disabling auto focus on iOS;
            }
          }, 10);
        }
      });
    }

    this.props.onChange(message ? null : value, value);
  }

  onBlur(e) {
    if (this.props.resetValidationOnFocus && this.context && this.context.formSubmitted) {
      const message = this.props.validator(this.state.value) || '';
      this.setState({
        message,
        valid: !message,
      });
    }
    this.props.onBlur(e);
    if (!this.props.blockBlur) {
      this.setState({
        touched: true,
      });
    }
  }

  onFocus(e) {
    if (this.props.resetValidationOnFocus && this.context && this.context.formSubmitted) {
      this.setState({
        message: '',
        valid: undefined,
      });
    }
    this.props.onFocus(e);
  }

  setErrorMessage(message) {
    this.setState({
      message,
      valid: false,
      touched: true,
    });
  }

  render() {
    let valid = null;
    let message = '';

    if ((!this.context && this.state.touched) || (this.context && this.context.formSubmitted)) {
      // eslint-disable-next-line
      valid = this.state.valid;
    }

    if (valid === false) {
      // eslint-disable-next-line
      message = this.state.message;
    }

    // const numberTypeFallback = isTouchDevice ? 'tel' : 'text';

    if (this.props.type === 'textarea') {
      return (
        <Textarea
          ref={ref => this.input = ref}
          name={this.props.name}
          className={this.props.className}
          label={this.props.label}
          value={this.state.value}
          tabIndex={this.props.tabIndex}
          disabled={this.props.disabled}
          readonly={this.props.readonly}
          message={message}
          valid={valid}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          iconPath={this.props.iconPath}
          customWidth={this.props.customWidth}
        />
      );
    }

    return (
      <MaskInput
        ref={ref => this.input = ref}
        name={this.props.name}
        className={this.props.className}
        type={this.props.type === 'number' ? 'text' : this.props.type}
        pattern={this.props.pattern}
        inputMode={this.props.inputMode}
        autoComplete={this.props.autoComplete}
        disabled={this.props.disabled}
        readonly={this.props.readonly}
        label={this.props.label}
        placeholder={this.props.placeholder}
        value={this.state.value}
        tabIndex={this.props.tabIndex}
        message={message}
        valid={valid}
        onChange={this.onChange}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        mask={this.props.mask}
        maskChar={this.props.maskChar}
        maxLength={this.props.maxLength}
        iconPath={this.props.iconPath}
        customWidth={this.props.customWidth}
        prefix={this.props.prefix}
        alwaysShowMask={this.props.alwaysShowMask}
      />
    );
  }
}
