import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import moment from 'moment';
import { closeEvent } from '../utils/api-widget';
import AppRoute from './AppRoute';
import PayMethodStep from './steps/pay-method/PayMethodStep';
import AlertAutoclose from './ui/AlertAutoclose';
import '../styles/app.scss';
import CloseIcon from '../assets/svg/close.svg';

const defaultTheme = {
  primaryBgColor: '#3d5afe', // #6966CC LK color
  primaryTextColor: '#ffffff',
};

class App extends React.PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
    updateStoreDataField: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    i18n: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  // static defaultProps = {};

  constructor(props) {
    super(props);

    this.state = {
    };

    this.tabletShadow = React.createRef();
  }

  onAlertClose = () => {
    this.props.updateStoreDataField({ field: 'apiError.shown', value: false });
  };

  onStepDidMount = () => {};

  onCloseClick = () => {
    closeEvent();
  };

  // onStepWillUnmount = () => {
  //   const html = window.document.getElementsByTagName('html')[0];
  //
  //   html.classList.remove(`page-${this.state.page}`);
  // };

  setLang = () => new Promise((resolve) => {
    const { i18n, t } = this.props;
    const locale = 'ru'; // TODO take it from url

    moment.defineLocale('short-locale', {
      parentLocale: locale,
    });

    i18n.on('languageChanged', () => {
      const relativeTimeWithPlural = (number, withoutSuffix, key) => {
        const format = {
          ss: t('common.short_second'),
          mm: t('common.short_minute'),
          hh: t('common.short_hour'),
          dd: t('common.short_day'),
          MM: t('common.short_month'),
          yy: t('common.short_year'),
        };

        return `${number} ${format[key]}`;
      };

      moment.updateLocale('short-locale', {
        relativeTime: {
          s: `1 ${t('common.short_second')}`,
          ss: relativeTimeWithPlural,
          m: relativeTimeWithPlural,
          mm: relativeTimeWithPlural,
          h: `1 ${t('common.short_hour')}`,
          hh: relativeTimeWithPlural,
          d: `1 ${t('common.short_day')}`,
          dd: relativeTimeWithPlural,
          M: `1 ${t('common.short_month')}`,
          MM: relativeTimeWithPlural,
          y: `1 ${t('common.short_year')}`,
          yy: relativeTimeWithPlural,
        },
      });

      moment.locale(locale);
      resolve();
    });

    i18n.changeLanguage(locale);
  });

  render() {
    const {
      match: { params: { sessionId }, path: basePath }, data, data: { apiError },
      updateStoreDataField, history, match, t,
    } = this.props;
    const redirectUrl = `/${sessionId}/methods/bank-card`;

    const commonPagesProps = {
      data,
      updateStoreDataField,
      history,
      match,
      onStepDidMount: this.onStepDidMount,
    };

    const alertShown = apiError && apiError.shown;
    const alertContent = (apiError && apiError.messageKey) ? t(`errors.${apiError.messageKey}`) : '';

    return (
      <ThemeProvider theme={defaultTheme}>
        <div className="app-component">
          <AlertAutoclose
            color={AlertAutoclose.Color.white}
            isOpen={alertShown}
            closeTimeoutMS={4400}
            closeOnClick
            onClose={this.onAlertClose}
          >
            <span dangerouslySetInnerHTML={{ __html: alertContent }} />
          </AlertAutoclose>

          <div className="content-block-header">
            <h2 className="h2" dangerouslySetInnerHTML={{ __html: t('common.card_binding') }} />
            <button className="close-btn" type="button" onClick={this.onCloseClick}>
              <CloseIcon />
            </button>
          </div>
          <div className="content-block-body">
            <Suspense fallback={<div />}>
              <Switch>
                <AppRoute key="methods-bank-card" path={`${basePath}/methods/bank-card`} component={PayMethodStep} pageProps={commonPagesProps} />
                <Route path={`/${sessionId}`} render={() => <Redirect to={redirectUrl} />} />
              </Switch>
            </Suspense>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

export default withTranslation()(App);
