/* eslint-disable react/sort-comp */
/* eslint-disable camelcase,no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { bindCard, getPaymentStatus } from '../../../utils/api-requests';
import { closeEvent, redirectEvent } from '../../../utils/api-widget';
import { isIFrame } from '../../../utils/checkIframe';
import { createAutoSubmitIframe } from '../../../utils/createAutoSubmitIframe';
import detectCardType from '../../../utils/detectCardType';
import { normalizeField } from '../../../utils/normalize';
import { parseQuery } from '../../../utils/parseQueryString';
import CustomScrollWrap from '../../ui/CustomScrollWrap';
import ValidateInput from '../../ui/ValidateInput';
import Button from '../../ui/Button';
import Form from '../../ui/Form';

import './payMethodStep.scss';

// https://developers.google.com/web/updates/2015/06/checkout-faster-with-autofill
const cardAutoCompleteDictionary = {
  card_number: { name: 'cardnumber', autoComplete: 'cc-number' },
  expire_date: { name: 'cc-exp', autoComplete: 'cc-exp' },
  cvc: { name: 'cvc', autoComplete: 'cc-csc' },
  holder_name: { name: 'ccname', autoComplete: 'cc-name' },
};

function getName(name) {
  return cardAutoCompleteDictionary[name].name;
}

function getAutoComplete(name) {
  return cardAutoCompleteDictionary[name].autoComplete;
}

function isValidCard(number) {
  let digit;
  let sum = 0;

  const splittedNumber = number.split('').reverse();
  const numberLength = splittedNumber.length;

  if (numberLength < 10) {
    return false;
  }

  for (let i = 0; i < numberLength; i++) {
    digit = splittedNumber[i];
    digit = +digit;
    if (i % 2) {
      digit *= 2;
      if (digit < 10) {
        sum += digit;
      } else {
        sum += digit - 9;
      }
    } else {
      sum += digit;
    }
  }
  return sum % 10 === 0;
}

function enrichExtraWithUserAgentInfoForNspk3ds(extra) {
  extra.language = window.navigator.language;
  extra.colorDepth = window.screen.colorDepth;
  extra.timezone = new Date().getTimezoneOffset();
  extra.screen_height = window.screen.height;
  extra.screen_width = window.screen.width;
}


let expireDateNeedToMoveCaret;
let pollingTimeoutId = 0;

const thisStepKey = 'methods';
const thisTabKey = 'bank-card';

const noop = () => {};

class BankCard extends React.PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
    sessionId: PropTypes.string.isRequired,
    methodConfig: PropTypes.object.isRequired,
    // calcFee: PropTypes.func.isRequired,
    updateStoreDataField: PropTypes.func.isRequired,
    onStepDidMount: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.scroll = React.createRef();
    // this.form =
    // this.calcFeeTimeoutId = null;
    this.isSubmitted = false;
    this.recursiveRecalcStarted = false;
    this.alreadyRedirected = true;
    this.iframeClosed = false;

    const { methodConfig } = this.props;
    const data = {};
    const state = {};

    methodConfig.fields.forEach((field) => {
      state[field.name] = '';
      state[`_${field.name}`] = data[field.name] || '';
      this[`input_${field.name}`] = React.createRef();
    });

    this.state = {
      ...state,
      payment_system: data.card_number ? detectCardType(data.card_number) : '',
      loading: false,
      transactionId: null,
    };
  }


  componentDidMount() {
    this.props.onStepDidMount();
    this._isMounted = true;
    // this.calcFee();
    window.addEventListener('message', this.postMessageHandler);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState._expire_date !== this.state._expire_date && expireDateNeedToMoveCaret) {
      const el = this.input_expire_date.current.input.input;
      if (typeof el.selectionStart === 'number') {
        const start = el.selectionStart;

        setTimeout(() => {
          el.selectionStart = start + 4;
          el.selectionEnd = start + 4;
        }, 10);
      }
      expireDateNeedToMoveCaret = false;
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('message', this.postMessageHandler);
    // clearTimeout(this.calcFeeTimeoutId);
  }

  postMessageHandler = (e) => {
    if (e.data.type && e.data.type === 'teko-bind-card-widget-CLOSE') {
      this.iframeClosed = true;
    } else if (e.data.type && e.data.type === 'teko-bind-card-widget-OPEN') {
      this.iframeClosed = false;
    }
  };

  onFieldChange = (field, value, rawValue) => {
    if (['cvc', 'card_number', 'expire_date'].indexOf(field) !== -1) {
      if (field === 'card_number') {
        this.setState({ [field]: value, [`_${field}`]: rawValue, payment_system: detectCardType(rawValue) });
      } else {
        if (this.state[field] === rawValue) return;
        const { methodConfig: { fields } } = this.props;

        if (field === 'expire_date') {
          if (rawValue.slice(0, 2).trim().length === 1) {
            if (rawValue[0] * 1 > 1) {
              expireDateNeedToMoveCaret = true;
              this.setState({ [field]: value, [`_${field}`]: `0${rawValue[0]}${rawValue.slice(2)}` });
              this.onScrollHeightUpdate();
              return;
            }
          }
          // else if (!moment(rawValue.slice(0, 2), 'MM').isValid()) {
          //   this.setState({ [field]: value }, () => {
          //     this.throttledCalcFee();
          //   });
          //   this.onScrollHeightUpdate();
          //   return;
          // }
        }

        const thisFieldIndex = fields.findIndex(fieldConfig => fieldConfig.name === field);
        const nextField = fields[thisFieldIndex + 1];

        this.setState({ [field]: value, [`_${field}`]: rawValue }, () => {
          if (value && this[`input_${nextField.name}`]) {
            setTimeout(() => {
              this[`input_${nextField.name}`].current.input.input.focus();
            }, 25); // potential bug :)
          }
        });
      }
    } else {
      let newVal = value;

      if (field === 'holder_name') {
        if (rawValue) {
          newVal = rawValue.toUpperCase();
        }
      }

      this.setState({ [field]: newVal, [`_${field}`]: rawValue });
    }

    this.onScrollHeightUpdate();
  };

  onCancelClick = () => {
    closeEvent();
  };

  onScrollHeightUpdate = () => {
    if (this.scroll.current) {
      this.scroll.current.forceUpdate();
    }
  };

  onSubmit = () => {
    const { sessionId } = this.props;
    const { card_number, expire_date, cvc, holder_name, loading } = this.state;
    const pan = normalizeField({ type: 'card_number' }, card_number);

    const data = {
      id: sessionId,
      cvc: normalizeField({ type: 'text' }, cvc),
      pan,
      expire: normalizeField({ type: 'text', mask: '99 / 99' }, expire_date),
      fullName: holder_name,
      extra: {
        user_agent: window.navigator.userAgent,
      },
    };

    enrichExtraWithUserAgentInfoForNspk3ds(data.extra);

    if (!loading) {
      this.setState({ loading: true });
    }

    bindCard(data)
      .then((result) => {
        this.alreadyRedirected = false;
        this.handleResult(result, pan);
        // this.setState({ transactionId: tx.id }, this.poll);
        // this.props.history.push(`/${sessionId}/pending/${tx.id}`);
        // if (isIFrame()) {
        //   // childWindow.location = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/${sessionId}/pending/${tx.id}`;
        // }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });

    this.isSubmitted = true;
  };

  recursiveRecalcScroll = () => {
    if (this.recursiveRecalcStarted) return;

    this.recursiveRecalcStarted = true;
    const startTime = window.performance.now();

    const recalc = () => {
      this.onScrollHeightUpdate();

      if (window.performance.now() < startTime + 300) {
        window.requestAnimationFrame(recalc);
      } else {
        this.recursiveRecalcStarted = false;
      }
    };

    window.requestAnimationFrame(recalc);
  };

  universalValidator = (value, validatorConfig, field) => {
    this.recursiveRecalcScroll();

    if (!validatorConfig) return '';

    if (validatorConfig.required && !value.trim()) {
      return this.props.t('common.field_required');
    }

    if (field === 'card_number' && !!value.trim() && !isValidCard(value.replace(/[^\d]/g, ''))) {
      return validatorConfig.regexpErrorMsg ? validatorConfig.regexpErrorMsg : this.props.t('common.field_invalid');
    }

    // if (field === 'expire_date' && !moment(value, 'MM / YY').isValid()) {
    //   return this.props.t('methods.invalid_date');
    // }

    if (field === 'expire_date' && moment(value, 'MM / YY').endOf('month').isBefore(moment())) {
      return this.props.t('methods.check_expire_date');
    }

    if (field === 'cvc' && value.length < 3) {
      return validatorConfig.regexpErrorMsg ? validatorConfig.regexpErrorMsg : this.props.t('common.field_invalid');
    }

    if (validatorConfig.regexp && !!value.trim() && field !== 'card_number') {
      const regexp = new RegExp(validatorConfig.regexp);

      if (!regexp.test(value)) {
        return validatorConfig.regexpErrorMsg ? validatorConfig.regexpErrorMsg : this.props.t('common.field_invalid');
      }
    }

    return '';
  };

  poll = (pan) => {
    const { sessionId } = this.props;

    const checkCondition = () => {
      if (this.iframeClosed) {
        pollingTimeoutId = setTimeout(checkCondition, process.env.PENDING_INTERVAL * 1);
      } else {
        getPaymentStatus({ id: sessionId })
          .then((result) => {
            if (['success', 'done', 'redirect', 'failed', 'confirmation'].indexOf(result.step) !== -1) {
              this.handleResult(result, pan, checkCondition);
            } else {
              pollingTimeoutId = setTimeout(checkCondition, process.env.PENDING_INTERVAL * 1);
            }
          })
          .catch((err) => {
            console.log('get payment request error:');
            console.log(err);
            pollingTimeoutId = setTimeout(checkCondition, process.env.PENDING_INTERVAL * 1);
          });
      }
    };

    pollingTimeoutId = setTimeout(checkCondition, process.env.PENDING_INTERVAL * 1);
  };

  handleResult = (result, pan, checkCondition) => {
    const slicedPan = pan.slice(-4);
    const cardBrand = this.state.payment_system;
    switch (true) {
      case (result.step === 'redirect' && result.authorization.redirect_url === 'hiddenFrame'):

        if (!this.new3dsFlowAlreadyHandled) {
          this.new3dsFlowAlreadyHandled = true;

          this.poll(pan);

          const {
            url: actionUrl,
            threeDSMethodData,
            url_2: actionUrl2,
            threeDSMethodData_2: threeDSMethodData2,
          } = result.authorization.params;

          createAutoSubmitIframe({ url: actionUrl, data: threeDSMethodData });

          if (actionUrl2) {
            createAutoSubmitIframe({ url: actionUrl2, data: threeDSMethodData2 });
          }
        } else if (checkCondition) {
          pollingTimeoutId = setTimeout(checkCondition, process.env.PENDING_INTERVAL * 1);
        }
        break;
      case (result.step === 'redirect'):
        if (this.alreadyRedirected) {
          pollingTimeoutId = setTimeout(checkCondition, process.env.PENDING_INTERVAL * 1);
          return;
        }
        const { redirect_url: rawUrl, method, params: rawParams } = result.authorization;

        const questionCharPosition = rawUrl.indexOf('?');
        let redirectUrl = rawUrl;
        let params = rawParams;

        if (questionCharPosition > -1) {
          params = {
            ...parseQuery(rawUrl.slice(questionCharPosition)),
            ...rawParams,
          };
          redirectUrl = rawUrl.slice(0, questionCharPosition);
        }

        if (isIFrame()) {
          this.alreadyRedirected = true;
          redirectEvent({ rawUrl, redirectUrl, rawParams, params, method });
        } else {
          this.setState({ redirectUrl, method, params }, () => {
            this.form.submit();
          });
        }

        break;
      case (result.step === 'success'):
      case (result.step === 'done'):
        this.props.history.replace(`/finish/success?maskedPan=${slicedPan}&brand=${cardBrand}`);
        // this.setState({ loading: false }); // TODO: mb disable form to prevent second submit?
        // successEvent(); // triggers in pageFinish
        break;
      case (result.step === 'failed'):
      default:
        this.props.history.replace(`/finish/error?maskedPan=${slicedPan}&brand=${cardBrand}`);
        // this.setState({ loading: false }); // TODO: mb disable form to prevent second submit?
        // errorEvent(); // triggers in pageFinish
        // this.props.history.replace(url.replace('pending', 'error'));
        break;
    }
  };

  getInput = (field) => {
    const { payment_system } = this.state;
    const cardIconPath = payment_system ? `${window.location.protocol}//${window.location.host}/assets/svg/payments/${payment_system}.svg` : '';

    switch (field.type) {
      case 'text':
      case 'number':
      default:
        if (field.name === 'card_number') {
          return (
            <ValidateInput
              ref={this[`input_${field.name}`]}
              key={field.name}
              type={field.type}
              pattern={field.pattern}
              inputMode={field.inputmode}
              label={field.label}
              disabled={field.disabled}
              readonly={field.readonly}
              name={getName(field.name)}
              autoComplete={getAutoComplete(field.name)}
              value={this.state[field.name]}
              defaultValue={this.state[`_${field.name}`]}
              mask="9999 9999 9999 9999 999"
              maskChar={null}
              onChange={(value, rawValue) => this.onFieldChange(field.name, value, rawValue)}
              validator={value => this.universalValidator(value, field.validatorConfig, field.name)}
              iconPath={cardIconPath}
              customWidth={field.width}
            />
          );
        }
        return (
          <ValidateInput
            ref={this[`input_${field.name}`]}
            key={field.name}
            type={field.type}
            pattern={field.pattern}
            inputMode={field.inputmode}
            label={field.label}
            disabled={field.disabled}
            readonly={field.readonly}
            name={getName(field.name)}
            autoComplete={getAutoComplete(field.name)}
            value={this.state[`_${field.name}`]}
            defaultValue={this.state[`_${field.name}`]}
            mask={field.mask}
            maskChar=" "
            maxLength={field.maxLength}
            onChange={(value, rawValue) => this.onFieldChange(field.name, value, rawValue)}
            validator={value => this.universalValidator(value, field.validatorConfig, field.name)}
            iconPath={field.icon}
            customWidth={field.width}
            onlyLatinChars={field.name === 'holder_name'}
          />
        );
    }
  };

  render() {
    const { methodConfig, t } = this.props;
    const { loading, redirectUrl, method, params, target } = this.state;

    return (
      <div className="content-block-body pay-method-step">
        <div className="ui-form-wrapper">
          <Form
            disabled={loading}
            focusFirstInput={false}
            onSubmit={this.onSubmit}
          >
            <div className="ui-scroll-outer">
              <CustomScrollWrap ref={this.scroll}>
                <div className="ui-scroll-inner">
                  <div className="ui-flex-form-inner">
                    {methodConfig.fields.map(field => this.getInput(field))}
                  </div>
                </div>
              </CustomScrollWrap>
            </div>
            <div className="button-wrap">
              <Button
                type="submit"
                label={t('common.bind_card')}
                loading={loading}
              />
              <button className="ui-button" type="button" disabled={loading} onClick={this.onCancelClick}>
                {t('common.cancel')}
              </button>
            </div>
            <div className="bind-check-hint" dangerouslySetInnerHTML={{ __html: t('common.bind_check_hint') }} />
          </Form>

          <form action={redirectUrl} method={method} ref={ref => this.form = ref} id="redirectFormSubmit" target={target}>
            {redirectUrl && typeof params === 'object' && Object.keys(params).map(key => <input type="hidden" name={key} key={key} value={params[key]} />)}
            {/*<input className="submit-btn" type="submit" ref={ref => this.submitRedirectBtn = ref} />*/}
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(BankCard);
