import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styled from 'styled-components';
import Color from 'color';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { defaultTheme } from '../../constants/appConfig';
import Spinner from '../../assets/svg/button-spinner.svg';

const PrimaryButton = styled.button`
  color: ${props => (props.theme ? props.theme.primaryTextColor : defaultTheme.primaryTextColor)};
  background: ${props => (props.theme ? props.theme.primaryBgColor : defaultTheme.primaryBgColor)};
  border: none;

  &:focus {
    background: ${props => Color(props.theme ? props.theme.primaryBgColor : defaultTheme.primaryBgColor).darken(0.2).hex()};
  }

  &:hover {
    background: ${props => Color(props.theme ? props.theme.primaryBgColor : defaultTheme.primaryBgColor).darken(0.1).hex()};
  }

  &:active {
    background: ${props => Color(props.theme ? props.theme.primaryBgColor : defaultTheme.primaryBgColor).darken(0.2).hex()};
  }

  &.disabled {
    color: ${props => (props.theme ? props.theme.primaryTextColor : defaultTheme.primaryTextColor)};
    background: ${props => (props.theme ? props.theme.primaryBgColor : defaultTheme.primaryBgColor)};
  }
`;

export default class Button extends React.PureComponent {
  static propTypes = {
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    type: PropTypes.string,
    tabIndex: PropTypes.string,
  };

  static defaultProps = {
    disabled: false,
    loading: false,
    type: 'button',
    tabIndex: '',
    onClick: () => {},
  };

  button = React.createRef();

  onClick = () => {
    if (!this.props.disabled && !this.props.loading) {
      this.props.onClick();
    }
  };

  render() {
    const className = classnames('ui-button', {
      disabled: this.props.disabled,
      loading: this.props.loading,
    });

    return (
      <PrimaryButton
        ref={this.button}
        className={className}
        type={this.props.type}
        disabled={this.props.disabled || this.props.loading}
        tabIndex={this.props.tabIndex}
        onClick={this.onClick}
      >
        <span className="ui-button-content">
          <ReactCSSTransitionGroup
            transitionName="ui-button-spinner"
            transitionEnterTimeout={300}
            transitionLeaveTimeout={300}
          >
            {this.props.loading && (
              <span className="ui-button-spinner">
                <Spinner className="ui-button-spinner-svg" />
              </span>
            )}
          </ReactCSSTransitionGroup>
          <span className="ui-button-text">{this.props.label}</span>
        </span>
      </PrimaryButton>
    );
  }
}
