import client from './api-client';

export function bindCard(data) {
  const backendUrl = process.env.BACKEND_URL;
  return client({ backendUrl, prefix: '/api/v2', path: '/bindCard', method: 'POST' }, data);
}

export function getPaymentStatus({ id }) {
  const backendUrl = process.env.BACKEND_URL;
  return client({ backendUrl, prefix: '/api/v2', path: `/bindCard/${id}`, method: 'GET' });
}
