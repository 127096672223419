import { getContrastColor } from '../utils/detectContrastColor';

export const defaultTheme = {
  backgroundColor: '#e8eaf6',
  primaryBgColor: '#3d5afe',
  primaryTextColor: '#fff',
  // primaryBgColor: '#8367e0', //fioletovii
  // primaryBgColor: '#7eb5de', //goluboi
  // blackColor: '#333333',
  // darkGreyColor: '#9e9e9e',
  // mediumGreyColor: '#dedede',
  // lightGreyColor: '#fafafa',
  // whiteColor: '#ffffff',
};
