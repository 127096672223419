import { updateStoreDataField } from '../actions/data';
import store from '../store/store';
import { errorCodeToMessageTranslationKey } from './errorCodeToMessageTranslationKey';

const defaultOptions = {
  prefix: '/api',
  method: 'GET',
};

function queryParams(params) {
  return Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');
}

/**
 * @param {*} options
 * @param {*} [data]
 * @returns {Promise}
 */
function client(options, data) {
  if (!options.path) {
    throw new Error('Request path not set');
  }

  const sessionHeaders = {};

  const { sessionId } = options;

  if (sessionId && options.withSessionHeader) {
    sessionHeaders['Session-Id'] = sessionId;
  }

  const apiHost = options.backendUrl || '';

  let fetchUrl = apiHost + (options.prefix || defaultOptions.prefix) + options.path;
  const hasBody = (options.method === 'POST' || options.method === 'PUT') && data;

  const fetchOptions = {
    mode: 'cors',
    // credentials: 'omit',
    method: options.method || defaultOptions.method,
    body: hasBody ? JSON.stringify(data) : undefined,
    headers: {
      ...sessionHeaders,
      'Content-Type': hasBody ? 'application/json; charset=utf-8' : 'text/plain',
    },
  };

  if (fetchOptions.method === 'GET' && data) {
    fetchUrl += `?${queryParams(data)}`;
  }

  const promise = fetch(fetchUrl, fetchOptions)
    .then(onRequestSuccess)
    .catch((data) => onRequestError(data, options.noNotifyOnCodes));

  return promise;
}

function onRequestSuccess(response) {
  if (process.env.NODE_ENV === 'development') {
    console.log(response);
  }

  if (!response.ok) {
    return response.json().then(error => Promise.reject({ error, response }));
  }

  if (isBlobContentType(response)) {
    return response.blob();
  }

  if (!isJsonContentType(response) || response.status === 204) {
    return Promise.resolve();
  }

  return new Promise((resolve, reject) => {
    response.json()
      .then(data => (data.success || data.status) ? resolve(data.result) : reject(data))
      .catch(() => reject({ result: { description: 'Bad json' }}));
  });
}

function onRequestError(error, noNotifyOnCodes = []) {
  if (process.env.NODE_ENV === 'development') {
    console.log(error);
  }

  const clientOrServerDefaultError = error instanceof Error ? 0 : 500;

  const errorCode = error.result ? (error.result.code * 1 || 500) : clientOrServerDefaultError;
  let errorText;

  if (errorCode) {
    errorText = error.result ? error.result.description : 'Server error';
  } else {
    errorText = String(error) || 'Something went wrong';
  }

  if (noNotifyOnCodes.indexOf(errorCode) < 0) {
    store.dispatch(updateStoreDataField({ field: 'apiError', value: { shown: true, messageKey: errorCodeToMessageTranslationKey[errorCode] } }));
  }

  // if (errorCode === CODE_UNAUTHORIZED) {
    // store.dispatch(logoutWithoutRequest());
  // }


  const errorObj = {
    code: errorCode,
    message: errorText,
  };

  return Promise.reject(errorObj);
}

function isJsonContentType(response) {
  try {
    const header = response.headers.get('content-type');
    return header && header.includes('application/json');
  }
  catch(e) {
    return false;
  }
}

function isBlobContentType(response) {
  try {
    const header = response.headers.get('content-type');
    return header && header.includes('application/octet-stream');
  }
  catch(e) {
    return false;
  }
}

export default client;
