import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import BankCard from './BankCard';
import './payMethodStep.scss';

const thisStepKey = 'methods';

const methodCfg = {
  isApplePayReady: true,
  key: 'bank-card-method',
  payment_type: 'api',
  fields: [
    {
      name: 'card_number',
      mask: '9999 9999 9999 9999',
      pattern: '[0-9]*',
      label: 'Номер карты',
      placeholder: '0000 0000 0000 0000',
      type: 'number',
      width: 100,
      inputmode: 'numeric',
      validatorConfig: {
        required: true,
        regexpErrorMsg: '',
        regexp: '',
      },
    },
    {
      name: 'expire_date',
      mask: '99 / 99',
      pattern: '[0-9]*',
      icon: 'assets/svg/cal.svg',
      label: 'Срок действия',
      placeholder: 'ММ / ГГ',
      type: 'number',
      width: 50,
      inputmode: 'numeric',
      validatorConfig: {
        required: true,
        regexpErrorMsg: 'Некорректная дата',
        regexp: '^(0[1-9]|1[0-2]) / ?([0-9]{4}|[0-9]{2})$',
      },
    },
    {
      name: 'cvc',
      pattern: '[0-9]*',
      icon: 'assets/svg/cvc.svg',
      label: 'Код CVC',
      placeholder: '***',
      hint: 'CVV/CVC код находится на задней стороне карты',
      type: 'password',
      width: 50,
      inputmode: 'numeric',
      maxLength: 3,
      validatorConfig: {
        required: true,
        regexpErrorMsg: '',
        regexp: '',
      },
    },
    {
      name: 'holder_name',
      icon: 'assets/svg/user.svg',
      label: 'Имя держателя карты',
      placeholder: 'IVAN PETROV',
      type: 'text',
      width: 100,
      validatorConfig: {
        required: false,
        regexpErrorMsg: '',
        regexp: '',
      },
    },
  ],
  isGooglePayReady: false,
  isYandexPayReady: false,
};

class PayMethodStep extends PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
    updateStoreDataField: PropTypes.func.isRequired,
    onStepDidMount: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.onStepDidMount();
  }

  render() {
    const { match: { params: { sessionId } } } = this.props;

    return (
      <BankCard {...this.props} methodConfig={methodCfg} sessionId={sessionId} />
    );
  }
}

export default withTranslation()(PayMethodStep);
