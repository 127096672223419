import { hot } from 'react-hot-loader/root';
import React, { Suspense } from 'react';
import {
  // Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import AppContainer from './AppContainer';
import PageError from './PageError';
import PageClose from './PageClose';
import PageFinish from './PageFinish';
import PageNotFound from './PageNotFound';

// import RoutesPage from './RoutesPage';

// function close() {
//   window.close();
//
//   return <Redirect to="/error" />;
// }

function Root() {
  return (
    <div className="root-component">
      <Suspense fallback={<div />}>
        <Switch>
          {/*<Route path="/" exact component={AppContainer} />*/}
          <Route exact path="/close" component={PageClose} />
          <Route exact path="/finish/:status(success|error)" component={PageFinish} />
          <Route exact path="/error" component={PageError} />
          <Route path="/:sessionId" component={AppContainer} />
          {/*{process.env.NODE_ENV === 'development' ? (<Route exact path="/routes" component={RoutesPage} />) : null}*/}
          <Route component={PageNotFound} />
        </Switch>
      </Suspense>
    </div>
  );
}

export default hot(Root);
