/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';
import { ViewportSize } from '../viewport/Viewport';
import withViewport from '../viewport/withViewport';

export const FormContext = React.createContext(null);

class Form extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    disabled: PropTypes.bool,
    focusFirstInput: PropTypes.bool,
    onBeforeSubmit: PropTypes.func,
    onSubmit: PropTypes.func,
    viewport: PropTypes.object.isRequired,
  };

  static defaultProps = {
    children: null,
    disabled: false,
    focusFirstInput: true,
    onBeforeSubmit: () => {},
    onSubmit: () => {},
  };

  constructor(props) {
    super(props);

    this.validateInputs = [];
    this.inputs = [];

    this.state = {
      formSubmitted: false,
      onValidateInputAddedToForm: this.onValidateInputAddedToForm,
      onInputAddedToForm: this.onInputAddedToForm,
    };
  }

  onValidateInputAddedToForm = (input) => {
    this.validateInputs = this.validateInputs.concat(input);
  };

  onInputAddedToForm = (input) => {
    const { viewport } = this.props;
    const isDesktop = viewport.size === ViewportSize.DESKTOP;

    this.inputs = this.inputs.concat(input);

    if (this.inputs.length === 1 && this.props.focusFirstInput && isDesktop) {
      input.focus();
      setTimeout(() => {
        const inputVal = input.value;
        input.value = '';
        input.value = inputVal;
      }, 10);
    }
  };

  onSubmit = (event) => {
    event.preventDefault();

    const { disabled } = this.props;
    if (disabled) return;

    let isValid = true;

    this.setState({ formSubmitted: true });

    this.validateInputs.forEach((input) => {
      if (!input.state.valid || (input.input && input.input.input && input.props.validator(input.input.input.value) !== '')) {
        isValid = false;
        input.forceUpdate();
      }
    });

    this.props.onBeforeSubmit();

    if (isValid) {
      this.props.onSubmit();
    }
  };

  render() {
    return (
      <FormContext.Provider value={this.state}>
        <form onSubmit={this.onSubmit} noValidate>
          {this.props.children}
        </form>
      </FormContext.Provider>
    );
  }
}

export default withViewport()(Form);
